import axios from "axios";
import React  from "react";
import { message, Typography} from 'antd';
import { trackPromise} from 'react-promise-tracker';
import moment from "moment";
import CommonUtil from "../components/util/CommonUtil";
import BillingUtil from "../components/estimate/BillingUtil";

const { Text } = Typography;


export default class ReportServices {


    static createCashSummaryReport = (inputData , dataUpdaterFunc=()=>{}) => {
       
        let resultStatus = true;
        trackPromise(
         axios.post("/api-route/api/v1/service/report/sales-report/cash-summary",inputData)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Cash summary report "+CommonUtil.status_messages.create_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }

    static createSalesTaxReport = (inputData , dataUpdaterFunc=()=>{}) => {
       
        let resultStatus = true;
        trackPromise(
         axios.post("/api-route/api/v1/service/report/sales-report",inputData)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Sales provider summary report "+CommonUtil.status_messages.create_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }

    static createInventoryReport = (inputData , dataUpdaterFunc=()=>{}) => {
       
        let resultStatus = true;
        trackPromise(
         axios.post("/api-route/api/v1/service/report/inventory",inputData)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Inventory report "+CommonUtil.status_messages.create_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }

    static createInventoryUsageReport = (inputData , dataUpdaterFunc=()=>{}) => {
       
        let resultStatus = true;
        trackPromise(
         axios.post("/api-route/api/v1/service/report/inventory-usage",inputData)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Inventory usage report "+CommonUtil.status_messages.create_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }

    static createBillableItemReport = (inputData , dataUpdaterFunc=()=>{}) => {
       
        let resultStatus = true;
        trackPromise(
         axios.post("/api-route/api/v1/service/report/billable-item",inputData)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Billable item report "+CommonUtil.status_messages.create_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }

    static createEndOfShiftReport = (inputData , dataUpdaterFunc=()=>{}) => {
       
        let resultStatus = true;
        trackPromise(
         axios.post("/api-route/api/v1/service/report/shift-end",inputData)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("End of shift report "+CommonUtil.status_messages.create_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }

    static createAccountReceivableReport = (inputData , dataUpdaterFunc=()=>{}) => {
       
        let resultStatus = true;
        trackPromise(
         axios.post("/api-route/api/v1/service/report/acct-rcvbl",inputData)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Account receivable report "+CommonUtil.status_messages.create_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }

    static createVaccineReport = (inputData , dataUpdaterFunc=()=>{}) => {
       
        let resultStatus = true;
        trackPromise(
         axios.post("/api-route/api/v1/service/report/vaccine",inputData)
        .then(response => response.data)
        .catch(err => {resultStatus=false;})
        .finally( () => {
            if(resultStatus){
                message.success("Vaccine report "+CommonUtil.status_messages.create_success)
            } else {
                message.error(CommonUtil.status_messages.error)
            }
         
            dataUpdaterFunc(resultStatus)
        }
    
        )
        );

    }

    static getAllReports = (dataUpdaterFunc=()=>{}) => {
        let resultantData = [];
        trackPromise(
         axios.get("/api-route/api/v1/service/report")
        .then(response => resultantData=response.data)
        .catch(err => console.log("Error occurred at getAllReports API: " + err))
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }

    static deleteReportById = (id , dataUpdaterFunc=()=>{}) => {
        let resultStatus = true;
        trackPromise(
         axios.delete("/api-route/api/v1/service/report/"+id)
        .then(response => response.data)
        .catch(err => {resultStatus=false;console.log("Error occurred at deleteReportById API: " + err);})
        .finally( () => {
                    if(resultStatus){
                        message.success("Report "+CommonUtil.status_messages.delete_success)
                    } else {
                        message.error(CommonUtil.status_messages.error)
                    }
                 
                    dataUpdaterFunc(resultStatus)
                }
            
                )
      
        );

    }

    static appointmentReport = (filterType,year,month,week,startDt,endDt,dataUpdaterFunc = () => { }) => {
    
        let resultantData = {};
        trackPromise(
            axios.get(`/api-route/api/v1/service/appointments?filterType=${filterType}&year=${year}&month=${month}&week=${week}&startDate=${startDt}&endDate=${endDt}`)
                .then(response => resultantData = response.data)
                .catch(err => {})
                .finally(() => {
                    dataUpdaterFunc(resultantData)
                })
                );
    }
    
    static clientTotal = (filterType,year,month,week,startDt,endDt,dataUpdaterFunc = () => { }) => {
    
        let resultantData = {};
        trackPromise(
            axios.get(`/api-route/api/v1/service/allClients?filterType=${filterType}&year=${year}&month=${month}&week=${week}&startDate=${startDt}&endDate=${endDt}`)
                .then(response => resultantData = response.data)
                .catch(err => {})
                .finally(() => {
                    dataUpdaterFunc(resultantData)
                })
                );
    }

    static clientActive = (filterType,year,month,week,startDt,endDt,dataUpdaterFunc = () => { }) => {    
        let resultantData = {};
        trackPromise(
            axios.get(`/api-route/api/v1/service/totalActiveClients?type=${filterType}&year=${year}&month=${month}&week=${week}&startDate=${startDt}&endDate=${endDt}`)
                .then(response => resultantData = response.data)
                .catch(err => {})
                .finally(() => {
                    dataUpdaterFunc(resultantData)
                })
                );
    }
    static clientInActive = (filterType,year,month,week,startDt,endDt,dataUpdaterFunc = () => { }) => {    
        let resultantData = {};
        trackPromise(
            axios.get(`/api-route/api/v1/service/totalInActiveClients?type=${filterType}&year=${year}&month=${month}&week=${week}&startDate=${startDt}&endDate=${endDt}`)
                .then(response => resultantData = response.data)
                .catch(err => {})
                .finally(() => {
                    dataUpdaterFunc(resultantData)
                })
                );
    }

    static cashReport = (filterType,year,month,week,startDt,endDt,dataUpdaterFunc = () => { }) => {    
        let resultantData = {};
        trackPromise(
            axios.get(`/api-route/api/v1/service/billingByCash?filterType=${filterType}&year=${year}&month=${month}&week=${week}&startDate=${startDt}&endDate=${endDt}`)
                .then(response => resultantData = response.data)
                .catch(err => {})
                .finally(() => {
                    dataUpdaterFunc(resultantData)
                })
                );
    }

    static cardReport = (filterType,year,month,week,startDt,endDt,dataUpdaterFunc = () => { }) => {    
        let resultantData = {};
        trackPromise(
            axios.get(`/api-route/api/v1/service/billingByCard?filterType=${filterType}&year=${year}&month=${month}&week=${week}&startDate=${startDt}&endDate=${endDt}`)
                .then(response => resultantData = response.data)
                .catch(err => {})
                .finally(() => {
                    dataUpdaterFunc(resultantData)
                })
                );
    }

    static checkReport = (filterType,year,month,week,startDt,endDt,dataUpdaterFunc = () => { }) => {    
        let resultantData = {};
        trackPromise(
            axios.get(`/api-route/api/v1/service/billingByCheck?filterType=${filterType}&year=${year}&month=${month}&week=${week}&startDate=${startDt}&endDate=${endDt}`)
                .then(response => resultantData = response.data)
                .catch(err => {})
                .finally(() => {
                    dataUpdaterFunc(resultantData)
                })
                );
    }

    static totalAmtReport = (filterType,year,month,week,startDt,endDt,dataUpdaterFunc = () => { }) => {    
        let resultantData = {};
        trackPromise(
            axios.get(`/api-route/api/v1/service/billingByTotal?filterType=${filterType}&year=${year}&month=${month}&week=${week}&startDate=${startDt}&endDate=${endDt}`)
                .then(response => resultantData = response.data)
                .catch(err => {})
                .finally(() => {
                    dataUpdaterFunc(resultantData)
                })
                );
    }

    static billingByPercentage = (filterType,year,month,week,startDt,endDt,dataUpdaterFunc = () => { }) => {    
        let resultantData = {};
        trackPromise(
            axios.get(`/api-route/api/v1/service/billingByPercentage?filterType=${filterType}&year=${year}&month=${month}&week=${week}&startDate=${startDt}&endDate=${endDt}`)
                .then(response => resultantData = response.data)
                .catch(err => {})
                .finally(() => {
                    dataUpdaterFunc(resultantData)
                })
                );
    }

    static apptStats = (filterType,year,month,week,startDt,endDt,dataUpdaterFunc = () => { }) => {   
        let resultantData = {};
        trackPromise(
            axios.get(`/api-route/api/v1/service/appointment?filterType=${filterType}&year=${year}&month=${month}&week=${week}&startDate=${startDt}&endDate=${endDt}`)
                .then(response => resultantData = response.data)
                .catch(err => {})
                .finally(() => {
                    dataUpdaterFunc(resultantData)
                })
                );
    }

    static doctorRevenue = (filterType,year,month,week,startDt,endDt,dataUpdaterFunc = () => { }) => {   
        let resultantData = {};
        trackPromise(
            axios.get(`/api-route/api/v1/service/doctorRevenue?filterType=${filterType}&year=${year}&month=${month}&week=${week}&startDate=${startDt}&endDate=${endDt}`)
                .then(response => resultantData = response.data)
                .catch(err => {})
                .finally(() => {
                    dataUpdaterFunc(resultantData)
                })
                );
    }

    static totalDocAppt = (filterType,year,month,week,startDt,endDt,dataUpdaterFunc = () => { }) => {
        let resultantData = {};
        trackPromise(
            axios.get(`/api-route/api/v1/service/totalDoctorAppointment?filterType=${filterType}&year=${year}&month=${month}&week=${week}&startDate=${startDt}&endDate=${endDt}`)
                .then(response => resultantData = response.data)
                .catch(err => {})
                .finally(() => {
                    dataUpdaterFunc(resultantData)
                })
                );
    }
    static invRetrive = (filterType,year,month,week,startDt,endDt,dataUpdaterFunc = () => { }) => {
        let resultantData = {};
        trackPromise(
            axios.get(`/api-route/api/v1/service/inventory-retrive?filterType=${filterType}&year=${year}&month=${month}&week=${week}&startDate=${startDt}&endDate=${endDt}`)
                .then(response => resultantData = response.data)
                .catch(err => {})
                .finally(() => {
                    dataUpdaterFunc(resultantData)
                })
                );
    }

    static invLab = (filterType,year,month,week,startDt,endDt,dataUpdaterFunc = () => { }) => {
        let resultantData = {};
        trackPromise(
            axios.get(`/api-route/api/v1/service/inventory-labs?filterType=${filterType}&year=${year}&month=${month}&week=${week}&startDate=${startDt}&endDate=${endDt}`)
                .then(response => resultantData = response.data)
                .catch(err => {})
                .finally(() => {
                    dataUpdaterFunc(resultantData)
                })
                );
    }

    static invService = (filterType,year,month,week,startDt,endDt,dataUpdaterFunc = () => { }) => {
        let resultantData = {};
        trackPromise(
            axios.get(`/api-route/api/v1/service/inventory-procedure?filterType=${filterType}&year=${year}&month=${month}&week=${week}&startDate=${startDt}&endDate=${endDt}`)
                .then(response => resultantData = response.data)
                .catch(err => {})
                .finally(() => {
                    dataUpdaterFunc(resultantData)
                })
                );
    }

    static apptCount = (filterType,year,month,week,startDt,endDt,dataUpdaterFunc = () => { }) => {
        let resultantData = {};
        trackPromise(
            axios.get(`/api-route/api/v1/service/appointmentDoctor?filterType=${filterType}&year=${year}&month=${month}&week=${week}&startDate=${startDt}&endDate=${endDt}`)
                .then(response => resultantData = response.data)
                .catch(err => {})
                .finally(() => {
                    dataUpdaterFunc(resultantData)
                })
                );
    }

    static drRevenue = (filterType,year,month,week,startDt,endDt,dataUpdaterFunc = () => { }) => {
        let resultantData = {};
        trackPromise(
            axios.get(`/api-route/api/v1/service/totalDoctorRevenue?filterType=${filterType}&year=${year}&month=${month}&week=${week}&startDate=${startDt}&endDate=${endDt}`)
                .then(response => resultantData = response.data)
                .catch(err => {})
                .finally(() => {
                    dataUpdaterFunc(resultantData)
                })
                );
    }

    static invDesc = (filterType,year,month,week,startDt,endDt,dataUpdaterFunc = () => { }) => {
        let resultantData = {};
        trackPromise(
            axios.get(`/api-route/api/v1/service/inventory-description?filterType=${filterType}&year=${year}&month=${month}&week=${week}&startDate=${startDt}&endDate=${endDt}`)
                .then(response => resultantData = response.data)
                .catch(err => {})
                .finally(() => {
                    dataUpdaterFunc(resultantData)
                })
                );
    }

    static vacPres = (filterType,year,month,week,startDt,endDt,dataUpdaterFunc = () => { }) => {
        let resultantData = {};
        trackPromise(
            axios.get(`/api-route/api/v1/service/vaccineByBranchId?filterType=${filterType}&year=${year}&month=${month}&week=${week}&startDate=${startDt}&endDate=${endDt}`)
                .then(response => resultantData = response.data)
                .catch(err => {})
                .finally(() => {
                    dataUpdaterFunc(resultantData)
                })
                );
    }

    static getInventoryNew = (url = "",dataUpdaterFunc=()=>{}) => {
        let resultantData = [];
        trackPromise(
         axios.get("/api-route/api/v1/service/inventory-created"+url)
        .then(response => resultantData=response.data)
        .catch(err => console.log("Error occurred at getInventoryNew API: " + err))
        .finally( () => {
            dataUpdaterFunc(resultantData)
        }

        )
        );

    }

}